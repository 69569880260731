import {
  Button,
  Flex,
  FormControl,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import UserItem from "../components/UserItem";
import { useEffect, useState } from "react";
import client from "../utils/client";
import { useForm } from "react-hook-form";
import { useAuth } from "../context/AuthContext";
import { Link } from "react-router-dom";

const Users = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPasswordOpen,
    onOpen: onPasswordOpen,
    onClose: onPasswordClose,
  } = useDisclosure();
  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();
  const toast = useToast();

  useEffect(() => {
    const request = async () => {
      const response = await client.get(`/users`, {
        headers: {
          Authorization: auth.user.token, // Inclua o token no cabeçalho da requisição
        },
      });
      response.data &&
        setUsers(response.data.sort((a, b) => a.name.localeCompare(b.name)));
    };
    request();
  }, [reload, auth.user.token]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const { login, passwordA: password, name } = data;
    await client
      .post("/users", {
        login: login.trim().toLowerCase(),
        password,
        name,
        type: "user",
      })
      .then(() => {
        onClose();
        reset();
        toast({
          title: "Conta criada!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setIsLoading(false);
        setReload(!reload);
      });
  };

  const handlePasswordChange = async (data) => {
    setIsLoading(true);
    try {
      await client
        .patch(`/users/${auth.user.id}`, {
          password: data.passwordB,
        })
        .then(() => {
          setIsLoading(false);
          reset();
          onPasswordClose();
          toast({
            title: "Senha alterada!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        });
    } catch (error) {
      setIsLoading(false);
      reset2();
      onPasswordClose();
      toast({
        title: "Algum erro aconteceu",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Modal isOpen={isPasswordOpen} onClose={onPasswordClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Mudar Senha Do Clube</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit2(handlePasswordChange)}>
            <ModalBody>
              <Flex flexDir={"column"} gap={2}>
                <FormControl>
                  Nova senha
                  <Input
                    type="password"
                    {...register2("passwordA", { required: true })}
                  />
                </FormControl>
                <FormControl>
                  Repita a senha
                  <Input
                    type="password"
                    {...register2("passwordB", {
                      required: true,
                      validate: (value) =>
                        value === watch2("passwordA") ||
                        "As senhas não coincidem",
                    })}
                  />
                  {errors2.passwordB && <p>{errors2.passwordB.message}</p>}
                </FormControl>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                isLoading={isLoading}
                colorScheme="red"
                mr={3}
                onClick={onPasswordClose}
              >
                Voltar
              </Button>
              <Button
                isLoading={isLoading}
                colorScheme="green"
                mr={3}
                type="submit"
              >
                Alterar Senha
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar Usuário</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <Flex flexDir={"column"} gap={3}>
                <FormControl>
                  Nome
                  <Input
                    type="text"
                    required
                    {...register("name", { required: true })}
                  />
                </FormControl>
                <FormControl>
                  Login
                  <Input
                    type="text"
                    required
                    {...register("login", { required: true })}
                  />
                </FormControl>
                <FormControl>
                  Senha
                  <Input
                    type="password"
                    required
                    {...register("passwordA", { required: true })}
                  />
                </FormControl>
                <FormControl>
                  Confirmar Senha
                  <Input
                    type="password"
                    required
                    {...register("passwordB", {
                      required: true,
                      validate: (value) =>
                        value === watch("passwordA") ||
                        "As senhas não coincidem",
                    })}
                  />
                  {errors.passwordB && <p>{errors.passwordB.message}</p>}
                </FormControl>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Flex gap={5}>
                <Button
                  isLoading={isLoading}
                  colorScheme="red"
                  onClick={() => {
                    reset();
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button isLoading={isLoading} colorScheme="green" type="submit">
                  Cadastrar
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Navbar locale={"users"} type={"manager"} />
      <Flex flexDir={"column"} alignItems={"center"} p={3} gap={5}>
        <Flex gap={5}>
          <Button onClick={onPasswordOpen} colorScheme="yellow">
            Alterar Senha Clube
          </Button>
          <Button onClick={onOpen} colorScheme="green">
            Adicionar
          </Button>
        </Flex>
        <TableContainer bgColor={"white"}>
          <Table variant="simple" size={"sm"}>
            <Thead>
              <Tr>
                <Th>Nome</Th>
                <Th>
                  <Text>Última</Text>
                  <Text>Mensalidade</Text>
                  <Text>Paga</Text>
                </Th>
                <Th textAlign={"center"}>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users &&
                users?.map(
                  (user) =>
                    user.type === "user" && (
                      <UserItem
                        key={user.userId}
                        userId={user.userId}
                        name={user.name}
                        installments={user.installments}
                        setReload={setReload}
                        reload={reload}
                      />
                    )
                )}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
      <Flex
        pos="fixed"
        bottom="0"
        left="0"
        width="100%"
        height="3rem"
        bg="#0B2027"
        zIndex="2"
        alignItems={"center"}
        justifyContent={"center"}
        gap={5}
      >
        <Link to={"https://wa.me/+5593992003313"} target="_blank">
          <Image
            borderRadius={8}
            src={`${process.env.PUBLIC_URL}/laranja.png`}
            h={"2rem"}
          />
        </Link>
        <Text
          fontSize={["0.9rem", "1.15rem"]}
          fontWeight={[400, 500]}
          color={"white"}
          fontFamily={"Afacad"}
        >
          © AppiDucks 2024
        </Text>
      </Flex>
    </>
  );
};

export default Users;
