import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Navbar from "../components/Navbar";
import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import Schedule from "../components/Schedule";
import { useAuth } from "../context/AuthContext";
import client from "../utils/client";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Link } from "react-router-dom";

const Home = () => {
  const [date, setDate] = useState(new Date());
  const today = new Date();
  const auth = useAuth();
  const [thisMonth, setThisMonth] = useState();

  useEffect(() => {
    const request = async () => {
      const response = await client.get(`users/${auth.user.id}`);
      if (response.data) {
        setThisMonth(
          response.data.installments.filter(
            (el) =>
              el.month === format(new Date(), "MMMM - yyyy", { locale: ptBR })
          )
        );
      }
    };
    auth.user.type !== "clube" && request();
  }, [auth.user.id, auth.user.type]);

  const correctedDate = new Date(today.setHours(today.getHours() + 3));

  return (
    <>
      <Navbar locale={"home"} type={"manager"} />
      <Flex flexDir={"column"} p={3} alignItems={"center"} gap={2}>
        <Flex gap={2} flexDir={"column"}>
          <Heading
            alignSelf={"flex-start"}
            variant={"outline"}
            textDecor={"underline"}
            textDecorationColor={"#0c35bb"}
            fontSize={"1.5rem"}
            mt={1}
          >
            Selecione o dia
          </Heading>
          <Calendar
            locale="pt-BR"
            minDate={
              auth.user.type === "clube"
                ? new Date(correctedDate.setDate(correctedDate.getDate() - 1))
                : correctedDate
            }
            maxDate={new Date(today.setDate(today.getDate() + 7))}
            onChange={setDate}
            value={date}
          />
        </Flex>

        {auth.user.type !== "clube" && thisMonth && thisMonth[0]?.isPaid && (
          <>{date && <Schedule date={new Date(date)} />}</>
        )}
        {auth.user.type !== "clube" && thisMonth && !thisMonth[0]?.isPaid && (
          <Flex gap={2} flexDir={"column"} alignItems={"center"}>
            <Heading
              variant={"outline"}
              textAlign={"center"}
              textDecor={"underline"}
              textDecorationColor={"#bb0c0c"}
              fontSize={"1.5rem"}
              mt={3}
            >
              É necessário estar em dia com a Academia para agendar horário!
            </Heading>
          </Flex>
        )}
        {auth.user.type === "clube" && (
          <Flex gap={2} flexDir={"column"} w={"100%"}>
            {date && <Schedule date={new Date(date)} />}
          </Flex>
        )}
      </Flex>
      <Flex
        pos="fixed"
        bottom="0"
        left="0"
        width="100%"
        height="3rem"
        bg="#0B2027"
        zIndex="2"
        alignItems={"center"}
        justifyContent={"center"}
        gap={5}
      >
        <Link to={"https://wa.me/+5593992003313"} target="_blank">
          <Image
            borderRadius={8}
            src={`${process.env.PUBLIC_URL}/laranja.png`}
            h={"2rem"}
          />
        </Link>
        <Text
          fontSize={["0.9rem", "1.15rem"]}
          fontWeight={[400, 500]}
          color={"white"}
          fontFamily={"Afacad"}
        >
          © AppiDucks 2024
        </Text>
      </Flex>
    </>
  );
};

export default Home;
