import { Button, Flex, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const Navbar = ({ locale }) => {
  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <Flex
      alignItems={"center"}
      p={3}
      bgColor={"#090636"}
      justifyContent={"space-between"}
      flexDir={"column"}
      gap={2}
    >
      <Heading color={"white"} fontSize={"lg"}>
        {auth.user.name}
      </Heading>
      <Flex gap={3}>
        {locale === "home" &&
          (auth.user?.type === "clube" ? (
            <>
              <Button
                border={"1px solid black"}
                colorScheme="yellow"
                size={"sm"}
                onClick={() => navigate("/sugestoes")}
              >
                Sugestões
              </Button>
              <Button
                border={"1px solid black"}
                colorScheme="linkedin"
                size={"sm"}
                onClick={() => navigate("/users")}
              >
                Usuários
              </Button>
            </>
          ) : (
            <>
              <Button
                border={"1px solid black"}
                colorScheme="yellow"
                size={"sm"}
                onClick={() => navigate("/sugestoes")}
              >
                Sugestões
              </Button>
              <Button
                border={"1px solid black"}
                colorScheme="linkedin"
                size={"sm"}
                onClick={() => navigate("/mensalidades")}
              >
                Mensalidade
              </Button>
            </>
          ))}
        {(locale === "mensalidades" ||
          locale === "sugestoes" ||
          locale === "users") && (
          <Button
            border={"1px solid black"}
            colorScheme="linkedin"
            size={"sm"}
            onClick={() => navigate("/")}
          >
            Home
          </Button>
        )}

        <Button
          border={"1px solid black"}
          colorScheme="red"
          size={"sm"}
          onClick={() => auth.logout(() => navigate("/login"))}
        >
          Sair
        </Button>
      </Flex>
    </Flex>
  );
};
export default Navbar;
