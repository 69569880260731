import { createBrowserRouter } from "react-router-dom";
import { RequireAuth } from "./context/AuthContext";
import Home from "./routes/Home";
import Installments from "./routes/Installments";
import Users from "./routes/Users";
import Login from "./routes/Login";
import Improvements from "./routes/Improvements";

const router = createBrowserRouter([
  { path: "/login", element: <Login /> },
  {
    path: "/",
    element: (
      <RequireAuth>
        <Home />
      </RequireAuth>
    ),
  },
  {
    path: "/mensalidades",
    element: (
      <RequireAuth>
        <Installments />
      </RequireAuth>
    ),
  },
  {
    path: "/users",
    element: (
      <RequireAuth>
        <Users />
      </RequireAuth>
    ),
  },
  {
    path: "/sugestoes",
    element: (
      <RequireAuth>
        <Improvements />
      </RequireAuth>
    ),
  },
]);

export default router;
