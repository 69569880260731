import axios from "axios";

const user = JSON.parse(localStorage.getItem("user")) || "";

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: user.token,
  },
});

export default client;
