import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import logo from "../images/icon-512x512.png";

const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const data = {
      login: event.target.email.value.trim().toLowerCase(),
      password: event.target.password.value,
    };

    try {
      await auth.signin(data, () => navigate("/", { replace: true }));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Flex p={2} gap={2} flexDir={"column"} alignItems={"center"}>
        <Image w={["40%", "10%"]} src={logo} />
        <Heading
          variant={"outline"}
          textDecor={"underline"}
          textDecorationColor={"#0c35bb"}
          textAlign={"center"}
        >
          Academia Clube St/Sgt {"\n"} 8° BEC
        </Heading>
      </Flex>

      <form onSubmit={handleLogin}>
        <Flex justifyContent={"center"}>
          <FormControl
            border={"1px solid #ccc"}
            borderRadius={"8px"}
            p={[2, 5]}
            bgColor={"white"}
            isRequired
            w={["90%", "80%", "80%", "40%"]}
            padding="6"
            boxShadow="lg"
            bg="white"
            mt={[8]}
          >
            <Heading
              size={"lg"}
              variant={"outline"}
              textDecor={"underline"}
              textDecorationColor={"#0c35bb"}
            >
              Login
            </Heading>
            <Flex mt={5} gap={5} flexDir={"column"} alignItems={"center"}>
              <Box w={"100%"}>
                <FormLabel htmlFor="email">Usuário</FormLabel>
                <Input type="text" id="email" name="email" />
              </Box>
              <Box w={"100%"}>
                <FormLabel htmlFor="password">Senha </FormLabel>
                <Input type="password" id="password" name="password" />
              </Box>

              <Button
                w={"40%"}
                isLoading={isLoading}
                type="submit"
                colorScheme="linkedin"
                variant="solid"
              >
                Logar
              </Button>
            </Flex>
          </FormControl>
        </Flex>
      </form>
      <Flex
        pos="fixed"
        bottom="0"
        left="0"
        width="100%"
        height="3rem"
        bg="#0B2027"
        zIndex="2"
        alignItems={"center"}
        justifyContent={"center"}
        gap={5}
      >
        <Link to={"https://wa.me/+5593992003313"} target="_blank">
          <Image
            borderRadius={8}
            src={`${process.env.PUBLIC_URL}/laranja.png`}
            h={"2rem"}
          />
        </Link>
        <Text
          fontSize={["0.9rem", "1.15rem"]}
          fontWeight={[400, 500]}
          color={"white"}
          fontFamily={"Afacad"}
        >
          © AppiDucks 2024
        </Text>
      </Flex>
    </>
  );
};
export default Login;
