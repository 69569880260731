import { useEffect, useState } from "react";
import Installment from "../components/Installment";
import Navbar from "../components/Navbar";
import client from "../utils/client";
import { useAuth } from "../context/AuthContext";
import { Flex, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Installments = () => {
  const [installments, setInstallments] = useState();
  const auth = useAuth();

  useEffect(() => {
    const request = async () => {
      const response = await client.get(`users/${auth.user.id}`);
      response.data && setInstallments(response.data.installments);
    };
    request();
  }, [auth.user.id]);

  return (
    <>
      <Navbar locale={"mensalidades"} />
      {installments?.map((el) => (
        <Installment key={el.month} isPaid={el.isPaid} month={el.month} />
      ))}
      <Flex
        pos="fixed"
        bottom="0"
        left="0"
        width="100%"
        height="3rem"
        bg="#0B2027"
        zIndex="2"
        alignItems={"center"}
        justifyContent={"center"}
        gap={5}
      >
        <Link to={"https://wa.me/+5593992003313"} target="_blank">
          <Image
            borderRadius={8}
            src={`${process.env.PUBLIC_URL}/laranja.png`}
            h={"2rem"}
          />
        </Link>
        <Text
          fontSize={["0.9rem", "1.15rem"]}
          fontWeight={[400, 500]}
          color={"white"}
          fontFamily={"Afacad"}
        >
          © AppiDucks 2024
        </Text>
      </Flex>
    </>
  );
};
export default Installments;
