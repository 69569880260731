import { DeleteIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FaKey } from "react-icons/fa6";
import { FaMoneyBill } from "react-icons/fa";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useState } from "react";
import client from "../../utils/client";
import { useForm } from "react-hook-form";

const UserItem = ({ userId, name, installments, reload, setReload }) => {
  const [loading, setLoading] = useState(false);
  const {
    isOpen: isInfoOpen,
    onOpen: onInfoOpen,
    onClose: onInfoClose,
  } = useDisclosure();
  const {
    isOpen: isPasswordOpen,
    onOpen: onPasswordOpen,
    onClose: onPasswordClose,
  } = useDisclosure();
  const {
    isOpen: isPaymentOpen,
    onOpen: onPaymentOpen,
    onClose: onPaymentClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const toast = useToast();

  const handleIstallment = async (data) => {
    data.preventDefault();

    setLoading(true);
    const dataToPay = new Date(data.target.month.value);
    dataToPay.setHours(dataToPay.getHours() + 3);

    await client.patch(`users/purchase/${userId}`, {
      isPaid: true,
      month: format(dataToPay, "MMMM - yyyy", { locale: ptBR }),
    });
    setLoading(false);
    setReload(!reload);
    onPaymentClose();
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await client.delete(`/users/${userId}`).then(() => {
        setLoading(false);
        setReload(!reload);
        onDeleteClose();
      });
    } catch (error) {
      setLoading(false);
      reset();
      onPasswordClose();
      toast({
        title: "Algum erro aconteceu",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handlePasswordChange = async (data) => {
    setLoading(true);
    try {
      await client
        .patch(`/users/${userId}`, {
          password: data.passwordB,
        })
        .then(() => {
          setLoading(false);
          reset();
          onPasswordClose();
          toast({
            title: "Senha alterada!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        });
    } catch (error) {
      setLoading(false);
      reset();
      onPasswordClose();
      toast({
        title: "Algum erro aconteceu",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const mesParaNumero = {
    janeiro: "01",
    fevereiro: "02",
    março: "03",
    abril: "04",
    maio: "05",
    junho: "06",
    julho: "07",
    agosto: "08",
    setembro: "09",
    outubro: "10",
    novembro: "11",
    dezembro: "12",
  };

  const dataOriginal = installments[installments.length - 1].month;
  const partesData = dataOriginal.split(" - ");
  const mes = partesData[0].trim().toLowerCase();
  const ano = partesData[1].trim();

  const dataConvertida = `${ano}-${mesParaNumero[mes]}`;

  return (
    <>
      <Tr>
        <Td>{name}</Td>
        <Td>
          {installments.reduce((acc, payment) => {
            return payment.isPaid ? payment.month : acc;
          }, null) || "Não pagou ainda"}
        </Td>
        <Td>
          <Flex gap={2}>
            <IconButton
              icon={<SearchIcon />}
              size={"sm"}
              colorScheme="blue"
              onClick={onInfoOpen}
            />
            <IconButton
              icon={<FaKey style={{ color: "white" }} />}
              size={"sm"}
              colorScheme="yellow"
              onClick={onPasswordOpen}
            />
            <IconButton
              icon={<FaMoneyBill style={{ color: "white" }} />}
              size={"sm"}
              colorScheme="green"
              onClick={onPaymentOpen}
            />
            <IconButton
              icon={<DeleteIcon />}
              size={"sm"}
              colorScheme="red"
              onClick={onDeleteOpen}
            />
          </Flex>
        </Td>
      </Tr>

      {/* MODAL INFORMAÇÃO */}
      <Modal isOpen={isInfoOpen} onClose={onInfoClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{name} - Detalhes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir={"column"} gap={2}>
              {installments.map((ins) => (
                <Flex justifyContent={"space-between"}>
                  <Text>{ins.month}</Text>
                  <Text color={ins.isPaid ? "green" : "red"}>
                    {ins.isPaid ? "Pago" : "Aberto"}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onInfoClose}>
              Voltar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* MODAL SENHA */}
      <Modal isOpen={isPasswordOpen} onClose={onPasswordClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{name} - Mudar Senha</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(handlePasswordChange)}>
            <ModalBody>
              <Flex flexDir={"column"} gap={2}>
                <FormControl>
                  Nova senha
                  <Input
                    type="password"
                    {...register("passwordA", { required: true })}
                  />
                </FormControl>
                <FormControl>
                  Repita a senha
                  <Input
                    type="password"
                    {...register("passwordB", {
                      required: true,
                      validate: (value) =>
                        value === watch("passwordA") ||
                        "As senhas não coincidem",
                    })}
                  />
                  {errors.passwordB && <p>{errors.passwordB.message}</p>}
                </FormControl>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                isLoading={loading}
                colorScheme="red"
                mr={3}
                onClick={onPasswordClose}
              >
                Voltar
              </Button>
              <Button
                isLoading={loading}
                colorScheme="green"
                mr={3}
                type="submit"
              >
                Alterar Senha
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      {/* MODAL MENSALIDADE */}
      <Modal isOpen={isPaymentOpen} onClose={onPaymentClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{name} - Lançar Mensalidade</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleIstallment}>
            <ModalBody>
              <Flex flexDir={"column"} gap={2}>
                <FormControl>
                  Mês
                  <Input
                    type="month"
                    name="month"
                    min={dataConvertida}
                    max={dataConvertida}
                  />
                </FormControl>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                isLoading={loading}
                colorScheme="red"
                mr={3}
                onClick={onPaymentClose}
              >
                Voltar
              </Button>
              <Button
                isLoading={loading}
                colorScheme="green"
                mr={3}
                type="submit"
              >
                Lançar Pagamento
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      {/* MODAL EXCLUIR */}
      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{name} - Excluir</ModalHeader>
          <ModalCloseButton />
          <form>
            <ModalBody>
              <Text>Você tem certeza de que quer excluir este usuário?</Text>
              <Text color={"red"} fontSize={"0.9rem"}>
                Todas as informações de pagamento serão perdidas
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button
                isLoading={loading}
                colorScheme="blue"
                mr={3}
                onClick={onDeleteClose}
              >
                Voltar
              </Button>
              <Button
                isLoading={loading}
                colorScheme="red"
                mr={3}
                onClick={handleDelete}
              >
                Excluir Usuário
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
export default UserItem;
