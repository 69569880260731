import { Flex, Text } from "@chakra-ui/react";

const Installment = ({ isPaid, month }) => {
  return (
    <Flex
      m={3}
      p={3}
      borderRadius={8}
      bgColor={isPaid ? "#00ff2a50" : "#ff000050"}
      justifyContent={"space-between"}
    >
      <Text fontWeight={500}>{month}</Text>
      <Text fontWeight={400}>{isPaid ? "Pago" : "Em Aberto"}</Text>
    </Flex>
  );
};
export default Installment;
