import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useAuth } from "../../context/AuthContext";
import { useState } from "react";
import client from "../../utils/client";

const ScheduleButton = ({
  isDisabled,
  time,
  space,
  date,
  presents,
  reload,
  setReload,
  isScheduled,
}) => {
  const auth = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isRemoveScheduleOpen,
    onOpen: onRemoveScheduleOpen,
    onClose: onRemoveScheduleClose,
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const correctedDate = new Date(date).setHours(new Date(date).getHours() + 3);

  const onBook = async () => {
    setIsLoading(true);
    try {
      await client
        .patch(`/days/${format(new Date(correctedDate), "yyyy-MM-dd")}`, {
          updateTime: {
            time,
            space: space - 1,
            presents: [...presents, auth.user.name],
          },
          userName: auth.user.name,
        })
        .then(() => setIsLoading(false))
        .then(() => onClose())
        .then(() => setReload(!reload))
        .then(() =>
          toast({
            title: "Agendado",
            description: "Você agendou este horário!",
            status: "success",
            duration: 5000,
            isClosable: true,
          })
        );
    } catch (error) {
      if (error.response.status === 400) {
        setIsLoading(false);
        onClose();
        toast({
          title: "Erro",
          description: "Você já agendou esse horário para este dia",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const onUnbook = async () => {
    setIsLoading(true);
    try {
      await client
        .patch(`/days/${format(new Date(correctedDate), "yyyy-MM-dd")}`, {
          updateTime: {
            time,
            space: space + 1,
            presents: presents.filter((name) => name !== auth.user.name),
          },
        })
        .then(() => setIsLoading(false))
        .then(() => onClose())
        .then(() => setReload(!reload))
        .then(() =>
          toast({
            title: "Desmarcado",
            description: "Você desmarcou este horário!",
            status: "success",
            duration: 5000,
            isClosable: true,
          })
        );
    } catch (error) {
      console.log(error);
      if (error) {
        setIsLoading(false);
        onClose();
        toast({
          title: "Erro",
          description: "Contate o administrador do sistema",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <Button
        colorScheme={isDisabled ? "red" : isScheduled ? "green" : "blue"}
        isDisabled={auth.user?.type === "clube" ? false : isDisabled}
        width="fit-content"
        size={"lg"}
        onClick={isScheduled ? onRemoveScheduleOpen : onOpen}
      >
        <Flex flexDirection="column">
          <Text fontSize={"0.9rem"} fontWeight={400}>
            {time}
          </Text>
          <Text fontSize={"0.9rem"} fontWeight={300}>
            {space} vagas
          </Text>
        </Flex>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {format(correctedDate, "dd/MM/yyyy")} - {time}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {auth.user?.type === "clube" ? (
              presents && presents.map((el) => <Text>{el}</Text>)
            ) : (
              <Text>
                Deseja{" "}
                <strong>
                  <u>marcar</u>
                </strong>{" "}
                este horário?
              </Text>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Fechar
            </Button>
            {auth.user?.type !== "clube" && (
              <Button
                isLoading={isLoading}
                onClick={onBook}
                colorScheme="green"
                mr={3}
              >
                Reservar
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isRemoveScheduleOpen} onClose={onRemoveScheduleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {format(correctedDate, "dd/MM/yyyy")} - {time}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Deseja{" "}
            <strong>
              <u>desmarcar</u>
            </strong>{" "}
            este horário?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onRemoveScheduleClose}>
              Fechar
            </Button>
            {auth.user?.type !== "clube" && (
              <Button
                isLoading={isLoading}
                onClick={onUnbook}
                colorScheme="green"
                mr={3}
              >
                Desmarcar
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ScheduleButton;
