import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useEffect, useState } from "react";
import client from "../utils/client";
import { FaTrashAlt } from "react-icons/fa";

const Improvements = () => {
  const auth = useAuth();
  const [improvements, setImprovements] = useState();
  const [improvementText, setImprovementText] = useState();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (auth.user.type === "clube") {
      const request = async () => {
        const response = await client.get("/improvements");
        response.data && setImprovements(response.data);
      };

      request();
    }
  }, [auth.user.type]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setImprovementText(inputValue);
  };

  const handleSend = async () => {
    setLoading(true);
    try {
      await client
        .post("/improvements", {
          suggestion: improvementText,
          name: auth.user.name,
        })
        .then(() => {
          setLoading(false);
          toast({
            title: "Sugestão enviada!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setImprovementText();
        });
    } catch (error) {}
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await client
        .delete(`/improvements/${id}`)
        .then(() => {
          setLoading(false);
          toast({
            title: "Sugestão deletada!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        })
        .then(() => {
          const newArr = improvements.filter((el) => el.improvementId !== id);
          setImprovements(newArr);
        });
    } catch (error) {}
  };

  return (
    <>
      <Navbar locale={"sugestoes"} />
      {auth.user.type === "user" && (
        <Flex
          flexDir={"column"}
          bgColor={"white"}
          m={3}
          gap={3}
          p={3}
          borderRadius={6}
        >
          <FormControl>
            <FormLabel>
              Deixe sua sugestão para a melhoria da academia!
            </FormLabel>
            <Textarea
              placeholder="Deixe sua sugestão..."
              onChange={handleInputChange}
              value={improvementText}
            />
          </FormControl>
          <Button isLoading={loading} colorScheme="green" onClick={handleSend}>
            Enviar
          </Button>
        </Flex>
      )}
      {auth.user.type === "clube" &&
        improvements?.map((el) => {
          return (
            <Flex
              flexDir={"column"}
              m={2}
              p={2}
              bgColor={"white"}
              borderRadius={6}
              key={el.improvementId}
            >
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Text fontSize={"0.8rem"} fontStyle={"italic"}>
                  {el.name}
                </Text>
                <IconButton
                  colorScheme="red"
                  icon={<FaTrashAlt />}
                  onClick={() => handleDelete(el.improvementId)}
                  size={"xs"}
                />
              </Flex>
              <Text style={{ paddingLeft: 10 }}>{el.suggestion}</Text>
            </Flex>
          );
        })}
      <Flex
        pos="fixed"
        bottom="0"
        left="0"
        width="100%"
        height="3rem"
        bg="#0B2027"
        zIndex="2"
        alignItems={"center"}
        justifyContent={"center"}
        gap={5}
      >
        <Link to={"https://wa.me/+5593992003313"} target="_blank">
          <Image
            borderRadius={8}
            src={`${process.env.PUBLIC_URL}/laranja.png`}
            h={"2rem"}
          />
        </Link>
        <Text
          fontSize={["0.9rem", "1.15rem"]}
          fontWeight={[400, 500]}
          color={"white"}
          fontFamily={"Afacad"}
        >
          © AppiDucks 2024
        </Text>
      </Flex>
    </>
  );
};
export default Improvements;
