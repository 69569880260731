import { Flex, Heading, Skeleton } from "@chakra-ui/react";
import ScheduleButton from "../ScheduleButton";
import { useEffect, useState } from "react";
import client from "../../utils/client";
import { format } from "date-fns";
import { useAuth } from "../../context/AuthContext";

const Schedule = ({ date }) => {
  const [foundDay, setFoundDay] = useState();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const auth = useAuth();

  const correctedDate = new Date(date).setHours(new Date(date).getHours() + 3);

  useEffect(() => {
    setFoundDay(false);
    const newDay = {
      day: format(new Date(correctedDate), "yyyy-MM-dd"),
      times: [
        { time: "09:00 às 10:00", space: 10, presents: [] },
        { time: "10:00 às 11:00", space: 10, presents: [] },
        { time: "11:00 às 12:00", space: 10, presents: [] },
        { time: "12:00 às 13:00", space: 10, presents: [] },
        { time: "13:00 às 14:00", space: 10, presents: [] },
        { time: "14:00 às 15:00", space: 10, presents: [] },
        { time: "15:00 às 16:00", space: 10, presents: [] },
        { time: "17:00 às 18:00", space: 10, presents: [] },
        { time: "18:00 às 19:00", space: 10, presents: [] },
        { time: "19:00 às 20:00", space: 10, presents: [] },
        { time: "20:00 às 21:00", space: 10, presents: [] },
        { time: "21:00 às 22:00", space: 10, presents: [] },
      ],
    };
    const formatedDateToSearch = format(new Date(correctedDate), "yyyy-MM-dd");
    const request = async () => {
      const response = await client.get(`/days/${formatedDateToSearch}`);
      if (response.data?.code === 404) {
        await client.post("/days", newDay).then(() => setFoundDay(newDay));
      } else {
        setFoundDay(response.data[0]);
      }
    };
    request().then(() => setLoading(false));
  }, [correctedDate, reload]);

  console.log(foundDay);

  return (
    <Skeleton isLoaded={!loading}>
      <Flex wrap={"wrap"} gap={3} justifyContent={"center"}>
        <Heading
          alignSelf={"flex-start"}
          variant={"outline"}
          w={"100%"}
          textDecor={"underline"}
          textDecorationColor={"#0c35bb"}
          fontSize={"1.5rem"}
          mt={3}
        >
          Selecione o Horário
        </Heading>
        {foundDay &&
          foundDay?.times.map((el) => (
            <ScheduleButton
              isDisabled={el.space > 0 ? false : true}
              key={el.time}
              time={el.time}
              space={el.space}
              presents={el.presents}
              isScheduled={
                el.presents.filter((pres) => pres === auth.user.name)[0]
                  ? true
                  : false
              }
              date={format(new Date(correctedDate), "yyyy-MM-dd")}
              setReload={setReload}
              reload={reload}
            />
          ))}
      </Flex>
    </Skeleton>
  );
};
export default Schedule;
