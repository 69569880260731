import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react";
import router from "./Router";
import { AuthProvider } from "./context/AuthContext";

//font-family: "Ubuntu", sans-serif

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "Roboto, sans-serif",
        fontWeight: 400,
        fontSize: "1rem",
      },
      heading: "Ubuntu, sans-serif",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={theme}>
    <AuthProvider>
      <Box bgColor="#00b7ff36" minH={"100vh"} pb={20}>
        <RouterProvider router={router} />
      </Box>
    </AuthProvider>
  </ChakraProvider>
);
