import { createContext, useContext, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { setInStorage, login } from "../utils/auth";
import NotAuthorized from "../routes/NotAuthorized";
import { useToast } from "@chakra-ui/react";

const AuthContext = createContext(null);

export const AuthProvider = (props) => {
  const userStored = localStorage.getItem("user");
  const toast = useToast();
  const [user, setUser] = useState(userStored ? JSON.parse(userStored) : null);

  const signin = async (data, callback) => {
    try {
      const response = await login(data, "/users/login");
      const { id, token, name, type } = response.data;
      const user = {
        id,
        token,
        name,
        type,
      };
      setInStorage("user", user);
      setUser(user);
      callback();
    } catch (error) {
      toast({
        title: "Login ou senha errados",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.log(error);
    }
  };

  const logout = (callback) => {
    localStorage.removeItem("user");
    setUser(null);
    callback();
  };

  return <AuthContext.Provider {...props} value={{ user, signin, logout }} />;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const RequireAuth = ({ children, requiredRole }) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth?.user?.token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  if (requiredRole && auth.user?.type !== requiredRole) {
    return <NotAuthorized />;
  }

  return children;
};
